
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import { environment } from 'environments/environment';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { PersonalConstants } from '../../common/personalConstants';
import { InternetRegistrationService } from './internet-registration.service';
import { LoggerService } from './logger.service';
import { TokenDataService } from './token-data.service';


@Injectable()
export class UserInfoService {
    constructor (private httpRequest: HttpClient,
        private apiCommonService: APICommonService,
        private LOGGER: LoggerService,
        private tokenDataService: TokenDataService,
        private RegistrationService: InternetRegistrationService) { }

    retrieveUserInfo(): Observable<any> {
        const userInfoUrl = `${environment.commonServiceAPIs.billingExperience}user-info`;


        try {
            const token = this.tokenDataService.getToken();

            const sessionId = window.sessionStorage.getItem('sessionId');

            const headers = this.apiCommonService.buildHeader({
                appClientId: environment.appProps.appClientId,
                authToken: token,
                nwAppId: null,
                contentType: 'text/plain',
                sessionId: sessionId,
                headerType: 'X-NW-MessageID'
            });

            return this.httpRequest.get(userInfoUrl, { headers: headers }).pipe(
                catchError((err) => observableOf(undefined)));
        } catch (e) {
            return observableOf(undefined);
        }
    }

    retrieveInternetRegistration(): Observable<any> {
        return this.retrieveUserInfo().pipe(mergeMap((userInfo) => this.RegistrationService.getInternetRegistration(userInfo.guid)));
    }
}
