
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, empty as observableEmpty } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TokenDataService } from './token-data.service';

@Injectable()
export class InternetRegistrationService {
    constructor (private http: HttpClient,
        @Inject('logger') private LOGGER,
        @Inject('constants') private constants,
        private tokenDataService: TokenDataService) {
    }

    patchInternetRegistration(guid: string, emailId: string, ecnNumber: string, partyType: string): Observable<any> {
        const data = JSON.stringify({
            type: 'GUID',
            contactPoints: {
                contactPointType: 'Email Address',
                electronicAddress: {
                    emailAddress: emailId,
                    emailAddressSource: 'Internet'
                }
            },
            customers: {
                enterpriseCustomerNumber: ecnNumber,
                partyType: partyType
            }
        });

        const clientId = environment.appProps.appClientId;
        const internetRegistrationUrl = `${environment.commonServiceAPIs.cimInternetRegistration + guid}?apikey=${clientId}`;

        return this.http.patch(internetRegistrationUrl, data, { headers: this.buildHeaders() }).pipe(
            map((response: Response) => response));
    }


    getInternetRegistration(guid: string): Observable<any> {
        const clientId = environment.appProps.appClientId;
        const internetRegistrationUrl = `${environment.commonServiceAPIs.cimInternetRegistration + guid}?apikey=${clientId}`;

        return this.http.get(internetRegistrationUrl, { headers: this.buildHeaders() }).pipe(
            map((response: Response) => response),
            catchError((response: Response) => {
                this.LOGGER.error('Error returning retriving Internet Registrations:', response);
                return observableEmpty();
            }));
    }

    buildHeaders(): HttpHeaders {
        const token = this.tokenDataService.getToken();
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${token}`);
        headers = headers.append('Content-Type', 'application/json');

        return headers;
    }
}
