import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { environment } from 'environments/environment';
import { PersonalConstants } from '../../common/personalConstants';
import { HttpHeaderService } from './httpheader.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import { UtilService } from './util.service';

@Injectable()
export class EBIService {
    constructor (
        private http: HttpClient,
        private apiCommon: APICommonService,
        private LOGGER: LoggerService,
        private authService: ClaimsOidcAuthService,
        private _session: SessionService,
        private util: UtilService,
        private httpHeader: HttpHeaderService) { }

    getClaimsIncidentDate(): string {
        let formattedDate: string;
        if (this._session.getFnolObject() && !this.util.isEmpty(this._session.getFnolObject().lossDate)) {
            const unformattedDate = new Date(this._session.getFnolObject().lossDate);
            formattedDate = `${unformattedDate.getMonth() + 1}/${unformattedDate.getDate()}/${unformattedDate.getFullYear()}`;
        }
        return !this.util.isEmpty(formattedDate) ? formattedDate : PersonalConstants.EBI_EVENT_LABEL.WEB;
    }

    getDraftClaimNumber(): void {
        return this._session.getFnolObject() && !this.util.isEmpty(this._session.getFnolObject().claimNumber) ?
            this._session.getFnolObject().claimNumber :
            PersonalConstants.EBI_EVENT_LABEL.WEB;
    }

    getClaimDetails(): any {
        return this._session.getFnolObject().submitClaimResponse &&
            !this.util.isEmpty(this._session.getFnolObject().submitClaimResponse.claimNumber) ?
            this._session.getFnolObject().submitClaimResponse :
            PersonalConstants.EBI_EVENT_LABEL.WEB;
    }

    getOpenClaimNumber(): void {
        return this._session.getFnolForm('confirmationResponse').claimNumber &&
            !this.util.isEmpty(this._session.getFnolForm('confirmationResponse').claimNumber) ?
            this._session.getFnolForm('confirmationResponse').claimNumber :
            PersonalConstants.EBI_EVENT_LABEL.WEB;
    }

    getReporterPNI(): string {
        return this._session.getFnolObject() &&
            !this.util.isEmpty(this._session.getFnolObject().claimReporter) &&
            !this.util.isEmpty(this._session.getInsuredPNI()) &&
            this._session.getFnolObject().claimReporter.publicID ===
            this._session.getInsuredPNI().publicID ?
            PersonalConstants.Application_Variables.YES :
            PersonalConstants.Application_Variables.NO;
    }

    getUserRole(): string {
        if (this._session.getSessionItem('registrationType') === 'unregistered-member-support-property') {
            return this._session.getSessionItem('howInvolved');
        } else if (this._session.getSessionItem('registrationType') === 'unregistered-member-property') {
            return PersonalConstants.EBI_EVENT_LABEL.MEMBER;
        } else if (this._session.getSessionItem('registrationType') === 'registered') {
            return PersonalConstants.EBI_EVENT_LABEL.MEMBER;
        } else if (this._session.getSessionItem('registrationType') === 'unregistered-non-member-deeplinking') {
            return PersonalConstants.EBI_EVENT_LABEL.NONMEMBER;
        } else {
            return PersonalConstants.EBI_EVENT_LABEL.NA;
        }
    }

    getPolicyOwner(): string {
        return this._session.getSessionItem('registrationType') === 'unregistered-member-support-property' ? 'No' : 'Yes';
    }

    getPolicyType(): string {
        let policyType = 'NA';
        if (this._session.getSessionItem('policy-type')) {
            policyType = this._session.getSessionItem('policy-type');
        } else if (this._session.getSessionItem('claimType')) {
            policyType = this._session.getSessionItem('claimType');
        } else if (this._session.getSessionItem('PRODUCT_TYPE')) {
            policyType = this._session.getSessionItem('PRODUCT_TYPE');
        }
            return policyType;
    }

    getEBIEventBaseDataObject(policy: any, claimsPage: string): object {
        return {
            [PersonalConstants.EBI_EVENT_TAGS.USER_FLOW]: (this._session.getSessionItem('authenticatedFlag') === 'true') ?
                PersonalConstants.EBI_EVENT_LABEL.AUTHENTICATED : PersonalConstants.EBI_EVENT_LABEL.UNAUTHENTICATED,
            [PersonalConstants.EBI_EVENT_TAGS.USER_ROLE]: this.getUserRole(),
            [PersonalConstants.EBI_EVENT_TAGS.COM_DENOM]: this._session.getSessionItem('nw-com-denom'),
            [PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB]: this.getDraftClaimNumber(),
            [PersonalConstants.EBI_EVENT_TAGS.FULL_POLICY_NB]: policy,
            [PersonalConstants.EBI_EVENT_TAGS.POLICY_OWNER]: this.getPolicyOwner(),
            [PersonalConstants.EBI_EVENT_TAGS.POLICY_TYPE]: this.getPolicyType(),
            [PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL]: PersonalConstants.EBI_EVENT_LABEL.UNKNOWN,
            [`${PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE}1`]: this.getClaimsIncidentDate(),
            [PersonalConstants.EBI_EVENT_TAGS.PAGE_NAME]: claimsPage
        };
    }

    getDashboardEBIEventBaseDataObject(policy: any): object {
        return {
            [PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE]: this._session.getDeviceInfo().browser,
            [PersonalConstants.EBI_EVENT_TAGS.USER_FLOW]: (this._session.getSessionItem('authenticatedFlag') === 'true') ?
                PersonalConstants.EBI_EVENT_LABEL.AUTHENTICATED : PersonalConstants.EBI_EVENT_LABEL.UNAUTHENTICATED,
            [PersonalConstants.EBI_EVENT_TAGS.USER_ROLE]: this.getUserRole(),
            [`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB}1`]: this._session.getSessionItem('claimNumber') ?
                this._session.getSessionItem('claimNumber') : 'NA',

            [PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID]: this._session.getSessionItem('nw-com-denom') ?
                this._session.getSessionItem('nw-com-denom') : 'NA',

            [PersonalConstants.EBI_EVENT_TAGS.PH_FIRST_NM]: this._session.getSessionItem('firstName') ?
                this._session.getSessionItem('firstName') : 'NA',
            [PersonalConstants.EBI_EVENT_TAGS.PH_LAST_NM]: this._session.getSessionItem('lastName') ?
                this._session.getSessionItem('lastName') : 'NA',

            [PersonalConstants.EBI_EVENT_TAGS.FULL_POLICY_NB]: policy,
            [PersonalConstants.EBI_EVENT_TAGS.POLICY_OWNER]: this.getPolicyOwner(),
            [PersonalConstants.EBI_EVENT_TAGS.POLICY_TYPE]: this.getPolicyType()
        };
    }


    fireEBIEvent(ebiID: string, claimsPage: string, eventSource?: string, additionalParams?: any): Promise<any> {
        const quickStartModel = JSON.parse(this._session.getSessionItem('nw-quickstart-model'));
        let policyNumber = quickStartModel ? quickStartModel.policyNumberMember : '';
        if (!policyNumber) {
            policyNumber = sessionStorage.getItem('nw-policyNumber') ? sessionStorage.getItem('nw-policyNumber') : 'NA';
        }
        let dataObject = {};
        let jsonWebToken;
        this.authService.getIdToken().subscribe((jwt) => {
            jsonWebToken = jwt;
        });
        dataObject = eventSource === 'Dashboard' ? {
            ...this.getDashboardEBIEventBaseDataObject(policyNumber)
        } :
            { ...this.getEBIEventBaseDataObject(policyNumber, claimsPage) };
        switch (ebiID) {
            case PersonalConstants.EBI_EVENTS.CLAIM_INTRO_LANDING:
                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE] = this._session.getDeviceInfo().browser;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_VERSION] = this._session.getDeviceInfo().browser_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.DEVICE_TYPE] = this._session.getDeviceInfo().deviceType;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_TYPE] = this._session.getDeviceInfo().os;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_VERSION] = this._session.getDeviceInfo().os_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE] = this.getUserRole();
                break;

            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_CONTACT_INCIDENT_LANDING:
            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_CONTACT_INCIDENT_CONTINUE:
            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_REVIEW_LANDING:
            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_EDIT_DELETE:
                dataObject[PersonalConstants.EBI_EVENT_TAGS.REPORTER_PNI] = !!this._session.getFnolObject().claimReporter ?
                    this.getReporterPNI() : PersonalConstants.EBI_EVENT_LABEL.NA;
                break;

            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_MEMBER_PROPERTY_INFORMATION_CONTINUE:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL];
                break;

            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_VALIDATION_FAILED:
            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_CONFIRM_CANCEL:
            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_BACK:
            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_REVIEW_CONTINUE:
            case PersonalConstants.EBI_EVENTS.LOGOUT_DUE_TO_IDLE_TIMEOUT:
                dataObject[PersonalConstants.EBI_EVENT_TAGS.TRIGGER_CHANNEL] = PersonalConstants.EBI_EVENT_LABEL.WEB;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.REPORTER_PNI] = !!this._session.getFnolObject().claimReporter ?
                    this.getReporterPNI() : PersonalConstants.EBI_EVENT_LABEL.NA;
                break;

            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_SUBMIT_SUCCESFUL:
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB}1`] = this.getOpenClaimNumber();
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS}1`] =
                    this._session.getFnolForm('confirmationResponse').claimState;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = PersonalConstants.EBI_EVENT_LABEL.WEB;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.TRIGGER_CHANNEL] = PersonalConstants.EBI_EVENT_LABEL.WEB;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.REPORTER_PNI] = !!this._session.getFnolObject().claimReporter ?
                    this.getReporterPNI() : PersonalConstants.EBI_EVENT_LABEL.NA;
                break;
            case PersonalConstants.EBI_EVENTS.CLAIMS_QS_CONFIRMATION_EMAIL_CLICK:
                [
                    PersonalConstants.EBI_EVENT_TAGS.USER_FLOW,
                    PersonalConstants.EBI_EVENT_TAGS.USER_ROLE,
                    PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB,
                    PersonalConstants.EBI_EVENT_TAGS.REPORTER_PNI
                ]
                    .forEach((tagName) => {
                        if (dataObject[tagName]) {
                            delete dataObject[tagName];
                        }
                    });

                const confirmationResponse = this._session.getFnolForm('confirmationResponse');
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB}1`] = this.getOpenClaimNumber();
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS}1`] = confirmationResponse.claimState;
                const claimInfo = this._session.getFnolForm('claimInfo');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LINK_NAME] =
                    (claimInfo?.adjuster?.email) || 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] =
                    (jsonWebToken && jsonWebToken?.ecn) ||
                    PersonalConstants.EBI_EVENT_LABEL.WEB;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.TRIGGER_CHANNEL] = PersonalConstants.EBI_EVENT_LABEL.WEB;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PH_FIRST_NM] = (
                    confirmationResponse?.mainContact?.firstName
                ) || 'UNKNOWN';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PH_LAST_NM] = (
                    confirmationResponse ||
                    confirmationResponse.mainContact ||
                    confirmationResponse.mainContact.lastName
                ) || 'UNKNOWN';

                const firstName = (jsonWebToken && jsonWebToken?.given_name);
                if (firstName) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = firstName;
                }

                const lastName = (jsonWebToken && jsonWebToken?.family_name);
                if (lastName) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = lastName;
                }

                const userId = (jsonWebToken && jsonWebToken?.userId);
                if (userId) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = userId;
                }

                const jsessionId = this._session.getSessionItem('jsessionId');
                if (jsessionId) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = jsessionId;
                }

                const lineOfBusiness = this.getPolicyType();
                if (lineOfBusiness) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = lineOfBusiness;
                }

                dataObject[PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD] = (
                    confirmationResponse?.mainContact?.primaryAddress?.state
                ) || 'ZZ';

                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }

                break;
            case PersonalConstants.EBI_EVENTS.CLAIMS_PAYMENT_OPTIONS_LANDING:
                if (dataObject[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1)]) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB] = dataObject[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1)];
                    delete dataObject[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1)];
                }

                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = 'Personal Auto';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS] = this._session.getSessionItem('claimState') ?
                    this._session.getSessionItem('claimState') : 'NA';
                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }
                break;
            case PersonalConstants.EBI_EVENTS.CLAIMS_PAYMENT_OPTIONS_ACCOUNT_DETAILS_SUBMIT:
                if (dataObject[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1)]) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB] = dataObject[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1)];
                    delete dataObject[(PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1)];
                }
                dataObject[PersonalConstants.EBI_EVENT_TAGS.BANK_ACCOUNT_TYPE] = additionalParams.paymentPreferredMethod;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.BANK_NAME] = additionalParams.bankName;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] =
                    additionalParams[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL];
                dataObject[PersonalConstants.EBI_EVENT_TAGS.DEVICE_TYPE] = this._session.getDeviceInfo().deviceType;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ACCT_TYPE_SELECTED] = additionalParams.acctTypeSelected;
                break;
            case PersonalConstants.EBI_EVENTS.FILE_VALIDATION_FAILED:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS}1`] = this._session.getSessionItem('claimState') ?
                    this._session.getSessionItem('claimState') : 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.COMMON_RETURN_MESSAGE] = additionalParams.data.error ?
                    additionalParams.data.error.status.toString() : 'Success';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM] = this._session.getSessionItem('nw-com-denom');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = 'NA';
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE}1`] = this.getClaimsIncidentDate();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE}1`] = 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD] = additionalParams.stateCode;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] = PersonalConstants.EBI_EVENT_LABEL.UNKNOWN;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = this._session.getSessionItem('firstName') ?
                    this._session.getSessionItem('firstName') : 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = this._session.getSessionItem('userId');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = this._session.getSessionItem('lastName') ?
                    this._session.getSessionItem('lastName') : 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.UUID] = 'NA';

                dataObject[PersonalConstants.EBI_EVENT_TAGS.FAILURE_DESCRIPTION] = additionalParams.data.error.code;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.FAILURE_REASON] = additionalParams.data.error.status;

                break;
            case PersonalConstants.EBI_EVENTS.DOC_UPLOAD_SUCCESS:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS}1`] = this._session.getSessionItem('claimState') ?
                    this._session.getSessionItem('claimState') : 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.COMMON_RETURN_MESSAGE] = additionalParams.data.error ?
                    additionalParams.data.error.status.toString() : 'Success';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM] = this._session.getSessionItem('nw-com-denom');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = (
                    (jsonWebToken && jsonWebToken?.ecn) ||
                    'NA'
                );
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE}1`] = this.getClaimsIncidentDate();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE}1`] = 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD] = additionalParams.stateCode;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] = PersonalConstants.EBI_EVENT_LABEL.UNKNOWN;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = jsonWebToken?.given_name ||
                    this._session.getSessionItem('firstName') ||
                    'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = this._session.getSessionItem('userId');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = (
                    jsonWebToken?.family_name ||
                    this._session.getSessionItem('lastName') ||
                    'NA'
                );
                dataObject[PersonalConstants.EBI_EVENT_TAGS.UUID] = 'NA';

                dataObject[PersonalConstants.EBI_EVENT_TAGS.DISPLAY_TEST_DRIVE] = 'NA';
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.DOC_TYPE}1`] = additionalParams.data.type;

                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = this._session.getSessionItem('jsessionId') ||
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM];

                delete additionalParams.data;
                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }
                break;
            case PersonalConstants.EBI_EVENTS.DOC_UPLOAD_FAILURE:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];
                if (dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB]) {
                    delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];
                }

                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS}1`] = this._session.getSessionItem('claimState') ?
                    this._session.getSessionItem('claimState') : 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.COMMON_RETURN_MESSAGE] =
                    (additionalParams.data.error && additionalParams.data.error.status) ?
                        additionalParams.data.error.status.toString() :
                        'UPLOAD FAILURE';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM] = this._session.getSessionItem('nw-com-denom');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = jsonWebToken?.ecn;
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE}1`] = this.getClaimsIncidentDate();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE}1`] = 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD] = additionalParams.stateCode;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] = PersonalConstants.EBI_EVENT_LABEL.UNKNOWN;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = (
                    jsonWebToken?.given_name ||
                    this._session.getSessionItem('firstName') ||
                    'NA'
                );
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = this._session.getSessionItem('userId');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = (
                    jsonWebToken?.family_name ||
                    this._session.getSessionItem('lastName') ||
                    'NA'
                );
                dataObject[PersonalConstants.EBI_EVENT_TAGS.UUID] = 'NA';
                if (ebiID === PersonalConstants.EBI_EVENTS.FILE_VALIDATION_FAILED) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.FAILURE_DESCRIPTION] = additionalParams.data.error.code;
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.FAILURE_REASON] = additionalParams.data.error.status.toString();
                }
                if (ebiID === PersonalConstants.EBI_EVENTS.DOC_UPLOAD_SUCCESS) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.DISPLAY_TEST_DRIVE] = 'NA';
                    dataObject[`${PersonalConstants.EBI_EVENT_TAGS.DOC_TYPE}1`] = additionalParams.data.type;
                }
                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = this._session.getSessionItem('jsessionId') ||
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM];

                [
                    PersonalConstants.EBI_EVENT_TAGS.PH_FIRST_NM,
                    PersonalConstants.EBI_EVENT_TAGS.PH_LAST_NM,
                    PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1,
                    PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS + 1,
                    PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE + 1,
                    PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD,
                    PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL,
                    PersonalConstants.EBI_EVENT_TAGS.POLICY_OWNER,
                    PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE + 1
                ]
                    .forEach((tagName) => {
                        if (additionalParams[tagName]) {
                            dataObject[tagName] = additionalParams[tagName];
                        }
                    });
                break;
            case PersonalConstants.EBI_EVENTS.CLAIM_INCIDENT_DETAILS:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_VERSION] = this._session.getDeviceInfo().browser_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS}1`] = this._session.getSessionItem('claimState') ?
                    this._session.getSessionItem('claimState') : 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM] = this._session.getSessionItem('nw-com-denom');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.DEVICE_TYPE] = this._session.deviceType();
                if (ebiID === PersonalConstants.EBI_EVENTS.CLAIM_INCIDENT_DETAILS) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.DISPLAY_TEST_DRIVE] = 'NA';
                }
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = 'NA';
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE}1`] = this.getClaimsIncidentDate();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE}1`] = 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_TYPE] = this._session.getDeviceInfo().os;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_VERSION] = this._session.getDeviceInfo().os_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD] = additionalParams.stateCode;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] = PersonalConstants.EBI_EVENT_LABEL.UNKNOWN;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = (
                    this._session.getSessionItem('firstName') ||
                    'NA'
                );
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = this._session.getSessionItem('userId');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = (
                    this._session.getSessionItem('lastName') ||
                    'NA'
                );
                dataObject[PersonalConstants.EBI_EVENT_TAGS.UUID] = 'NA';
                break;
            case PersonalConstants.EBI_EVENTS.RETRIEVE_DOCUMENT_DETAILS:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_VERSION] = this._session.getDeviceInfo().browser_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS}1`] = this._session.getSessionItem('claimState') ?
                    this._session.getSessionItem('claimState') : 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM] = this._session.getSessionItem('nw-com-denom');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.DEVICE_TYPE] = this._session.getDeviceInfo().deviceType;
                if (ebiID === PersonalConstants.EBI_EVENTS.CLAIM_INCIDENT_DETAILS) {
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.DISPLAY_TEST_DRIVE] = 'NA';
                }
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = jsonWebToken?.ecn;
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE}1`] = this.getClaimsIncidentDate();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[`${PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE}1`] = 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_TYPE] = this._session.getDeviceInfo().os;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_VERSION] = this._session.getDeviceInfo().os_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD] = additionalParams.stateCode;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL] = PersonalConstants.EBI_EVENT_LABEL.UNKNOWN;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = this._session.getSessionItem('userId');
                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM];
                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE] = this._session.getDeviceInfo().browser;

                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = (
                    jsonWebToken?.given_name ||
                    this._session.getSessionItem('firstName') ||
                    'NA'
                );

                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = (
                    jsonWebToken?.family_name ||
                    this._session.getSessionItem('lastName') ||
                    'NA'
                );

                [
                    PersonalConstants.EBI_EVENT_TAGS.PH_FIRST_NM,
                    PersonalConstants.EBI_EVENT_TAGS.PH_LAST_NM,
                    PersonalConstants.EBI_EVENT_TAGS.CLAIM_NB + 1,
                    PersonalConstants.EBI_EVENT_TAGS.CLAIM_STATUS + 1,
                    PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE + 1,
                    PersonalConstants.EBI_EVENT_TAGS.PH_STATE_ALPHA_CD,
                    PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL,
                    PersonalConstants.EBI_EVENT_TAGS.POLICY_OWNER,
                    PersonalConstants.EBI_EVENT_TAGS.LOSS_CAUSE + 1
                ]
                    .forEach((tagName) => {
                        if (additionalParams[tagName]) {
                            dataObject[tagName] = additionalParams[tagName];
                        }
                    });
                dataObject[PersonalConstants.EBI_EVENT_TAGS.UUID] = 'NA';
                break;
            case PersonalConstants.EBI_EVENTS.LOAD_TRACKER:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.POLICY_TYPE] = this.getPolicyType();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = jsonWebToken?.userId;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = jsonWebToken?.ecn;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = jsonWebToken?.given_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = jsonWebToken?.family_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM];
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.UUID] = 'NA';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';

                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE] = this._session.getDeviceInfo().browser;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_VERSION] = this._session.getDeviceInfo().browser_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.DEVICE_TYPE] = this._session.getDeviceInfo().deviceType;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_TYPE] = this._session.getDeviceInfo().os;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_VERSION] = this._session.getDeviceInfo().os_version;

                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }
                break;
            case PersonalConstants.EBI_EVENTS.CLAIMS_COVERAGE_SUMMARY:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = jsonWebToken?.ecn;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = this._session.getSessionItem('jsessionId') ||
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM];
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = jsonWebToken?.given_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = jsonWebToken?.family_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = jsonWebToken?.userId;

                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }
                break;
            case PersonalConstants.EBI_EVENTS.LOAD_FAQ:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = jsonWebToken?.ecn;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = this._session.getSessionItem('jsessionId') ||
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM];
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = jsonWebToken?.given_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = jsonWebToken?.family_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = jsonWebToken?.userId;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.UUID] = 'NA';

                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }
                break;
            case PersonalConstants.EBI_EVENTS.LOAD_CLAIMS_CONTACTS:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.PAGE_NAME];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FLOW];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.CLAIMS_DISPLAY_COUNT] = '1';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = jsonWebToken?.ecn;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = this._session.getSessionItem('jsessionId') ||
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM];
                dataObject[PersonalConstants.EBI_EVENT_TAGS.LOB] = this.getPolicyType();
                dataObject[PersonalConstants.EBI_EVENT_TAGS.MEMBER] = (this.getUserRole() === 'MEMBER') ? 'YES' : 'NO';
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = jsonWebToken?.given_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = jsonWebToken?.family_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = jsonWebToken?.userId;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.UUID] = 'NA';

                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }
                break;
            case PersonalConstants.EBI_EVENTS.OYS_SELECTION_CONFIRMATION:
            case PersonalConstants.EBI_EVENTS.DRIVE_IN_SELECTION_CONFIRMATION:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.PAGE_NAME];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.POLICY_OWNER];
                delete dataObject[(PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE + 1)];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = (
                    this._session.getSessionItem('jsessionId') ||
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM]
                );

                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = jsonWebToken?.ecn;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = jsonWebToken?.given_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = jsonWebToken?.family_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = jsonWebToken?.userId;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ROLE] = this.getUserRole();

                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE] = this._session.getDeviceInfo().browser;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_VERSION] = this._session.getDeviceInfo().browser_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.DEVICE_TYPE] = this._session.getDeviceInfo().deviceType;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_TYPE] = this._session.getDeviceInfo().os;

                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }
                break;
            case PersonalConstants.EBI_EVENTS.CHANGE_OYS:
            case PersonalConstants.EBI_EVENTS.CHANGE_DRIVE_IN:
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.PAGE_NAME];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.DRAFT_CLAIM_NB];
                delete dataObject[PersonalConstants.EBI_EVENT_TAGS.POLICY_OWNER];
                delete dataObject[(PersonalConstants.EBI_EVENT_TAGS.INCIDENT_DATE + 1)];
                delete dataObject[(PersonalConstants.EBI_EVENT_TAGS.PREVIOUS_URL)];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.ECN] = jsonWebToken?.ecn;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.JSESSION_ID] = this._session.getSessionItem('jsessionId') ||
                    dataObject[PersonalConstants.EBI_EVENT_TAGS.COM_DENOM];

                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_FIRST_NM] = jsonWebToken?.given_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_LAST_NM] = jsonWebToken?.family_name;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.USER_ID] = jsonWebToken?.userId;

                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_TYPE] = this._session.getDeviceInfo().browser;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.BROWSER_VERSION] = this._session.getDeviceInfo().browser_version;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.DEVICE_TYPE] = this._session.getDeviceInfo().deviceType;
                dataObject[PersonalConstants.EBI_EVENT_TAGS.OS_TYPE] = this._session.getDeviceInfo().os;

                if (additionalParams) {
                    dataObject = {
                        ...dataObject,
                        ...additionalParams
                    };
                }
                break;
        }
        // Fire Event
        return Promise.resolve();
    }

    fireEvent(eventId, data): Promise<any> {
        const url = `${environment.commonServiceAPIs.ebiEndPoint}?client_id=${environment.appProps.appClientId}`;

        const headers = this.apiCommon.buildHeader({
            appClientId: null,
            authToken: this.httpHeader.getAccessToken(),
            nwAppId: PersonalConstants.ebiEventProperties.appId,
            contentType: PersonalConstants.APPLICATION_TYPE.APPLICATION_JSON,
            headerType: 'X-Nw-Transaction-Id'
        });


        const body = {
            'appCookie': PersonalConstants.ebiEventProperties.appCookie,
            'eventId': eventId,
            'url': environment.commonServiceAPIs.ebiEndPoint,
            'data': data
        };

        this.LOGGER.debug('', body);

        return new Promise(
            (resolve: (value: any) => void): void => {
                this.http.post(url, body, { headers: headers, responseType: 'text' })
                    .subscribe((response) => resolve(response));
            }
        );
    }
}
