import { Injectable } from '@angular/core';
import { PersonalConstants } from 'app/common/personalConstants';
import { environment } from 'environments/environment';
import { HttpService } from './http.service';
import { HttpHeaderService } from './httpheader.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import { UtilService } from './util.service';

@Injectable()
export class CTMService {
    model: any;
    insuranceAccountData: any;
    constructor (
        private _session: SessionService,
        private logger: LoggerService,
        private httpService: HttpService,
        private _httpHeaderService: HttpHeaderService,
        private utilService: UtilService
    ) {
    }

    getenterpriseNumber(): any {
        const jwt_ecn = this._session.getSessionItem('jwt_ecn');
        const ECN = jwt_ecn ? jwt_ecn.replace(jwt_ecn.match(/(\bunidentified\.ecn:|unidentified\.enterpriseCustomerNumber:\b)/g), '') : '';
        return ECN;
    }

    getSourceApplication(ctmParams): any {
        if (ctmParams.claimID) {
            return 'ClaimCenter';
        } else {
            return 'Policy System';
        }
    }

    getBusinessItemId(ctmParams): any {
        const fnolData = this._session.getFnolObject();
        if (ctmParams.claimID) {
            return ctmParams.claimID;
        } else {
            return fnolData.policy.policyNumber;
        }
    }

    buildCTMData(eventId: string, ctmParams: any): void {
        const customer = {
            name: `${this.utilService.capitalize(this._session.getInsuredPNI().lastName)}, ${
                this.utilService.capitalize(this._session.getInsuredPNI().firstName)}`,
            enterpriseCustomerNumber: this.getenterpriseNumber()
        };

        const businessItem = {
            sourceApplication: this.getSourceApplication(ctmParams),
            id: this.getBusinessItemId(ctmParams)
        };
        const dataObject = {
            customer: customer,
            businessItem: businessItem,
            applicationSource: 'Internet Servicing',
            productType: ctmParams.LOB,
            interactionGrouping: {
                id: this._session.getSessionItem('sessionId')
            },
            detailedDescription: ctmParams.detailedDescription,
            topicDescription: ctmParams.topicDescription
        };
        this.fireEvent(eventId, dataObject);
    }

    fireEvent(eventId: string, dataObject: any): Promise<any> {
        const sessionlogparams = this._session.getFnolObject() || {};
        console.log(`Fire CTM for event: ${eventId}`);
        console.log('Fire CTM for dataobject: ', JSON.stringify(dataObject));

        const requestBody = this.createRequestBody();
        requestBody['mappingId'] = eventId;
        requestBody['customer'] = dataObject.customer;
        requestBody['businessItem'] = dataObject.businessItem;
        requestBody['productType'] = dataObject.productType;
        requestBody['detailedDescription'] = dataObject.detailedDescription;
        requestBody['topicDescription'] = dataObject.topicDescription;
        requestBody['applicationSource'] = dataObject.applicationSource;
        requestBody['interactionGrouping'] = dataObject.interactionGrouping;
        const m = new Date();
        const dateString: string =
            `${m.getUTCFullYear()}-${
            (`0${m.getUTCMonth() + 1}`).slice(-2)}-${
            (`0${m.getUTCDate()}`).slice(-2)} ${
            (`0${m.getUTCHours()}`).slice(-2)}:${
            (`0${m.getUTCMinutes()}`).slice(-2)}:${
            (`0${m.getUTCSeconds()}`).slice(-2)}`;

        requestBody['activityDate'] = dateString;

        const postRequest = {
            uri: `?apikey=${environment.appProps.appClientId}`,
            requestPayload: requestBody,
            baseUrl: environment.commonServiceAPIs.ctmEndpoint,
            headers: this._httpHeaderService.getCtmServiceHeaders(),
            type: '',
            GUID: '',
            responseType: 'text'
        };
        this.logger.info('CTM Request', { request: postRequest }, sessionlogparams);
        return new Promise((resolve: (value: Response) => void): void => {
            this.httpService.invokeHttpPost(postRequest).subscribe(
                (response) => {
                    this.logger.info('CTM Response', { response: response }, sessionlogparams);
                    console.log(`CTM Success for ${eventId}`, response);
                    resolve(response);
                },
                (error: any) => {
                    this.logger.error('CTM Fail', { error: error }, sessionlogparams);
                    console.log('CTM Fail');
                    resolve(error);
                }
            );
        });
    }

    private createRequestBody(): any {
        const requestBody: any = {};
        requestBody['authenticationId'] = 'MyNW';
        requestBody['transactionId'] = this._httpHeaderService.getTransactionId();
        return requestBody;
    }
}
