import { Injectable } from '@angular/core';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import { PersonalConstants } from 'app/common/personalConstants';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable()
export class IamBypass {
    constructor (
        private apiCommonService: APICommonService,
        private sessionService: SessionService
    ) { }

    userArrivedViaBypass(): boolean {
        return sessionStorage.getItem('arrivedViaIAMBypass') === 'true';
    }

    refreshSession(userId: string): Observable<any> {
        const euaURL = environment.MONTY_AUTH_URL;
        const key = this.sessionService.getSessionItemDecrypted('encodedKey');
        const euaToken = btoa(`${environment.appProps.appClientId}:${key}`);

        const twentySeconds = 20 * 1000;
        return this.apiCommonService.generateTokenForTest({
            username: userId,
            message_id: 'TEST',
            grant_type: 'password',
            scope: 'openid',
            realm: 'member',
            auth_method: 'ping-sts',
            password: environment.iamBypass.acctInfo
        }, euaURL, euaToken).pipe(
            timeout(twentySeconds),
            catchError(() => of({})),
            map((response) => ({
                successful: response.hasOwnProperty('access_token'),
                authParams: response
            }))
        );
    }
}
